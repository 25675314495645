<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row mt-4">
			<div class="col-xs-12 col-lg-8 d-flex">
				<div class="h2 text-muted py-3">
					{{ translate('payment_method') }}
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<ul class="list-group w-100">
					<li
						v-if="paymentMethods.length > 1"
						class="list-group-item">
						<div class="row">
							<div class="col-12">
								<div class="h4 my-2">
									{{ translate('select_payment_method') }}
								</div>
							</div>
						</div>
						<div class="row mt-3">
							<div class="col-8">
								<div
									:class="['xs', 'sm'].includes(windowWidth) ? 'btn-group-vertical w-100' : 'btn-group'"
									class="btn-group-lg payment-method"
									role="group">
									<b-button
										v-for="item in paymentMethods"
										:key="item.id"
										:variant="selectedPaymentMethodName === item.attributes.method_code_name ? 'primary' : 'light'"
										@click="selectedPaymentMethodName = item.attributes.method_code_name">
										{{ translate(item.attributes.method_code_name) }}
									</b-button>
								</div>
							</div>
						</div>
					</li>
					<li class="list-group-item">
						<b-row
							v-if="canSplitWithWallet && canSplitWithWalletConsideringMinAmount && allowedPaymentMethodsWithWallet.includes(selectedPaymentMethodName)"
							class="mx-1">
							<b-col v-if="selectedPaymentMethodName">
								<switch-toggle
									id="split_payment"
									v-model="splitWithWallet"
									name="wallet_split"
									variant="success"
									class="mr-2"
									pill />
								<label for="wallet_split">
									{{ translate('split_between_two_payment_methods', { first: translate('cashondelivery'), second: translate(selectedPaymentMethodName) }) }}
								</label>
								<hr class="p-1 m-1">
							</b-col>
							<b-col
								v-if="showSectionsAsCards"
								cols="12">
								<b-alert
									show
									v-html="translate('split_with_wallet_instructions')" />
							</b-col>
						</b-row>
						<form
							@submit.prevent="showSplitPaymentMessages() ? confirmSplitPayment() : prepareForStorage()"
							@keydown="clearValidationError($event.target.name);"
							@change="handleAddressFormChange($event.target.name);">
							<ul
								:class="{ 'mx-3': showSectionsAsCards }"
								class="list-group">
								<li
									v-if="showSectionsAsCards"
									class="list-group-item h-100 p-0">
									<div
										class="row p-2 border-bottom m-0 pl-2 alert-secondary-darker">
										<div class="col my-auto align-middle">
											<div class="h5 m-0">
												{{ translate('cashondelivery') }}
											</div>
										</div>
									</div>
									<div class="px-4 py-2">
										<b-row>
											<b-col cols="12">
												<wallet-payment
													:show-title="false"
													:wallet-balance-loading="loadingWalletBalance"
													:balance="balance"
													:wallet-type="wallet.type"
													:wallet-type-errors="validationErrors[`${steps[$route.name].validation}.payment_method.wallet_type`]"
													:wallet-password-errors="validationErrors[`${steps[$route.name].validation}.payment_method.wallet_password`]"
													@passwordChanged="wallet.password = $event" />
											</b-col>
											<b-col cols="6">
												<label
													for="amount"
													class="label">
													{{ translate('amount_to_pay_with_payment_method', { method: translate('cashondelivery') }) }}
													<span class="text-danger">*</span>
												</label>
											</b-col>
											<b-col cols="6">
												<input
													v-model="walletSplitAmount"
													name="amount"
													type="number"
													class="form-control text-right"
													aria-describedby="amount"
													:placeholder="`${currency.symbol}0.00`"
													@input="verifyAndFixWalletAmount">
											</b-col>
											<b-col cols="12">
												<template v-if="hasAmountError">
													<p
														v-for="error in validationErrors[`${steps[$route.name].validation}.payment_method.wallet_split_amount`]"
														:key="error"
														class="custom-invalid-feedback animated fadeIn pl-2 mb-0 text-right"
														v-text="error" />
												</template>
											</b-col>
										</b-row>
									</div>
								</li>
								<li
									:class="{ 'border-0': !showSectionsAsCards }"
									class="list-group-item h-100 p-0">
									<div
										v-if="showSectionsAsCards"
										class="row p-2 border-bottom m-0 pl-2 alert-secondary-darker">
										<div class="col my-auto align-middle">
											<div class="h5 m-0">
												{{ translate(selectedPaymentMethodName) }}
											</div>
										</div>
									</div>
									<div class="pb-2">
										<div class="row">
											<b-col
												v-if="showSectionsAsCards"
												cols="12">
												<div class="d-flex justify-content-between align-items-center mt-3 px-4">
													<div>
														{{ translate('amount_to_pay_with_payment_method', { method: translate(selectedPaymentMethodName) }) }}:
													</div>
													<div>
														<b>{{ remainingForOtherMethod | currency(currency.code, translate) }}</b>
													</div>
												</div>
											</b-col>
											<b-col cols="12">
												<ul class="list-group w-100">
													<li class="list-group-item rounded-0 pb-0 border-0">
														<credit-card-payment-register-v2
															v-if="isCardPaymentMethod"
															ref="creditCardV2Register"
															:show-title="paymentMethods.length === 1"
															:payment-method-conditions="conditions"
															:can-split-payment="showSplitPayment && !splitWithWallet"
															:split-payment-import="splitPayment"
															:split-payment-amount-import="splitPaymentAmount"
															:cart-total="cartTotal"
															:form="form"
															redirect-to="BecomeDistributorPack"
															@preparing="preparing = $event" />
														<cash-payment
															v-if="selectedPaymentMethodName === 'checkmo'"
															:show-title="paymentMethods.length === 1"
															:payment-method-conditions="conditions"
															redirect-to="BecomeDistributorPack" />
														<wallet-payment
															v-if="selectedPaymentMethodName === 'cashondelivery' && $user.details().type === affiliate"
															:show-title="paymentMethods.length === 1"
															:wallet-balance-loading="loadingWalletBalance"
															:balance="balance"
															:wallet-type="wallet.type"
															:wallet-type-errors="validationErrors[`${steps[$route.name].validation}.payment_method.wallet_type`]"
															:wallet-password-errors="validationErrors[`${steps[$route.name].validation}.payment_method.wallet_password`]"
															@passwordChanged="wallet.password = $event" />
														<bitcoin-payment
															v-if="selectedPaymentMethodName === 'banktransfer'"
															:show-title="paymentMethods.length === 1"
															:payment-method-conditions="conditions"
															redirect-to="BecomeDistributorPack" />
														<sezzle-payment
															v-if="selectedPaymentMethodName === 'sezzle'"
															:show-title="paymentMethods.length === 1"
															:payment-method-conditions="conditions"
															redirect-to="BecomeDistributorPack"
															sezzle-details="sezzle_details" />
														<oxxo-payment
															v-if="selectedPaymentMethodName === 'oxxopay'"
															:show-title="paymentMethods.length === 1"
															:payment-method-conditions="conditions"
															redirect-to="BecomeDistributorPack" />
														<g-cash-payment
															v-if="selectedPaymentMethodName === 'gcash'"
															:show-title="paymentMethods.length === 1"
															:payment-method-conditions="conditions"
															redirect-to="BecomeDistributorPack" />
														<pay-pal-payment
															v-if="selectedPaymentMethodName === 'lptpaypal'"
															:show-title="paymentMethods.length === 1"
															:payment-method-conditions="conditions"
															redirect-to="BecomeDistributorPack"
															paypal-details="lptpaypal_details_purchase" />
													</li>
												</ul>
											</b-col>
										</div>
									</div>
								</li>
							</ul>
						</form>
					</li>
					<li
						v-if="conditions.isAvailable"
						class="list-group-item">
						<div class="row">
							<div class="col-12">
								<div class="h4 my-2">
									{{ translate('billing_information') }}
								</div>
							</div>
						</div>
						<div
							:class="{'px-4': windowWidth === 'md', 'px-5': !['xs','md'].includes(windowWidth) }"
							class="row">
							<div
								v-if="isCardPaymentMethod"
								:class="{'px-0': !['xs'].includes(windowWidth) }"
								class="col-12">
								<b-alert show>
									{{ translate('billing_disclaimer') }}
								</b-alert>
							</div>
							<div class="col-12 mb-3">
								<div class="custom-control custom-checkbox">
									<input
										id="customCheck1"
										v-model="sameAs"
										type="checkbox"
										class="custom-control-input">
									<label
										class="custom-control-label"
										for="customCheck1">{{ translate('same_as_shipping') }}</label>
								</div>
							</div>
						</div>
						<template v-if="!sameAs">
							<div class="row">
								<div class="col-12">
									<div class="h4 my-2">
										{{ translate( addAddress ? 'add_new_address' : 'select_your_address') }}
									</div>
								</div>
							</div>
							<div
								:class="{'px-4': windowWidth === 'md', 'px-5': !['xs','md'].includes(windowWidth) }"
								class="row">
								<div
									:class="{'px-0': !['xs'].includes(windowWidth) }"
									class="col-12 address-list">
									<address-radio-button
										:address-data="addressData"
										:address-loading="addressLoading"
										:form-import="form"
										:hide-new-address="addAddress"
										:display-add-new-address="false"
										:borderless="true"
										@add-address="addressFormState" />
									<div
										v-if="!addAddress && !addressLoading"
										class="row">
										<div class="col-12">
											<hr class="mt-0">
										</div>
									</div>
									<div
										v-if="addAddress && !addressLoading"
										class="pt-4">
										<address-country-form
											:user-country="true"
											:allow-change-country="true"
											:form-import="form"
											:errors-import="addressErrors.errors"
											:prefix-errors="''"
											:display-cancel-button="false"
											:display-submit-button="false"
											:display-goback-button="false"
											:display-override-toggle="showOverrideValidation" />
									</div>
								</div>
							</div>
							<div
								v-if="!addressLoading"
								:class="{'px-4': windowWidth === 'md', 'px-5': !['xs','md'].includes(windowWidth) }"
								class="row">
								<div
									:class="{'mb-3': ['xs', 'sm'].includes(windowWidth), 'px-0': !['xs'].includes(windowWidth) }"
									class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
									<button
										v-if="addAddress && hasAddressData"
										:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : 'pl-0'"
										class="btn btn-link b-4 float-left"
										@click="addressFormState">
										{{ translate('see_address_list') }}
									</button>
									<button
										v-else-if="!addAddress"
										:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : 'pl-0'"
										class="btn btn-link b-4 float-left"
										@click="addressFormState">
										{{ translate('use_another_address') }}
									</button>
								</div>
								<div
									v-if="addAddress"
									:class="{'px-0': !['xs'].includes(windowWidth) }"
									class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
									<button
										:disabled="(!hasAddressData && !addAddress)"
										:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
										class="btn btn-outline-primary b-4 float-right"
										@click="newAddress(form)">
										{{ translate('save_address_button') }}
									</button>
								</div>
							</div>
						</template>
						<div
							:class="{'px-4': windowWidth === 'md', 'px-5': !['xs','md'].includes(windowWidth) }"
							class="row">
							<div
								v-if="typeof validationErrors[`${steps[$route.name].validation}.billing.address_id`] !== 'undefined'"
								id="address"
								:class="{'px-0': !['xs'].includes(windowWidth) }"
								class="col-12">
								<b-alert
									variant="danger"
									show>
									<div
										v-for="error in validationErrors[`${steps[$route.name].validation}.billing.address_id`]"
										:key="error">
										<span
											v-text="error" />
										<br>
									</div>
								</b-alert>
							</div>
						</div>
					</li>
				</ul>
				<b-row
					no-gutters
					class="mt-3">
					<b-col>
						<b-alert
							:show="showSectionsAsCards"
							class="pb-1 mb-0"
							variant="warning">
							<div class="form-check">
								<input
									id="walletAndOtherMethodTerms"
									v-model="walletSplitAgreement"
									name="walletAndOtherMethodTerms"
									class="form-check-input"
									type="checkbox">
								<label for="walletAndOtherMethodTerms">
									{{ translate('wallet_and_other_payment_method_terms', { name: appName }) }}
								</label>
							</div>
						</b-alert>
					</b-col>
				</b-row>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-4 ml-auto my-4 text-right">
				<div
					class="btn-group w-100"
					role="group">
					<b-button
						:disabled="!!preparing"
						variant="light"
						size="lg"
						@click="nextStep(true)">
						{{ translate('previous') }}
					</b-button>
					<b-button
						:disabled="(splitWithWallet && !walletSplitAgreement) || !splitPaymentAgreementState() || validating || !!preparing || addAddress || !conditions.isAvailable"
						variant="primary"
						size="lg"
						@click="showSplitPaymentMessages() ? confirmSplitPayment() : prepareForStorage()">
						<i
							v-if="validating"
							class="fas fa-spinner fa-spin mr-2" />{{ translate('continue_to_confirm') }}
					</b-button>
				</div>
			</div>
		</div>
		<same-address-as-card-statement-modal
			v-model="showSameAddressAsCardStatementModal"
			:country-to-check="country"
			:card-type="selectedCardInfo.card_type"
			:last-digits="selectedCardInfo.last_digits"
			:address="selectedCardInfo.address"
			@confirm="savePaymentStep()" />
	</div>
</template>
<script>
import { ALLOW_CHANGE_COUNTRY_TO_USER as allowAnotherCountry } from '@/settings/AddressBook';
import { WALLET_TYPES } from '@/settings/Wallets';
import {
	// UNPROCESSABLE,
	SYSTEM_ALERT,
} from '@/settings/Errors';
// import { ADDRESS_VERIFICATION_COUNTRIES as addressVerificationCountries } from '@/settings/Shipping';
import {
	ALLOWED_PAYMENT_METHODS_WITH_WALLET,
	MIN_AMOUNT_BY_PAYMENT_METHODS_WITH_WALLET,
} from '@/settings/Purchase';
import { affiliate, distributor } from '@/settings/Roles';
import WindowSizes from '@/mixins/WindowSizes';
import AddressRadioButton from '@/components/AddressRadioButton';
import AddressCountryForm from '@/components/AddressCountryForm';
import SwitchToggle from '@/components/Switch';
import AddressBook from '@/util/AddressBook';
import Country from '@/util/Country';
import Cart from '@/util/Cart';
import Wallets from '@/util/Wallets';
import CommonMix from './mixins/Common';
import { currency } from '@/config/Filters';
import WalletPayment from '@/components/Payment/WalletPayment.vue';
import CashPayment from '@/components/Payment/CashPayment.vue';
import BitcoinPayment from '@/components/Payment/BitcoinPayment.vue';
import SezzlePayment from '@/components/Payment/SezzlePayment.vue';
import OxxoPayment from '@/components/Payment/OxxoPayment.vue';
import GCashPayment from '@/components/Payment/GCashPayment.vue';
import PayPalPayment from '@/components/Payment/PayPalPayment.vue';
import CreditCardPaymentRegisterV2 from '@/components/Payment/CreditCardPaymentRegisterV2.vue';
import PaymentMethods from '@/mixins/PaymentMethods';
import SameAddressAsCardStatementModal from '@/components/SameAddressAsCardStatementModal/index.vue';
import { CREDIT_CARD_FORM_METHODS } from '@/settings/CreditCard';

export default {
	name: 'BecomeDistributorPaymentMethod',
	components: {
		SameAddressAsCardStatementModal,
		AddressRadioButton,
		AddressCountryForm,
		SwitchToggle,
		WalletPayment,
		CashPayment,
		BitcoinPayment,
		SezzlePayment,
		OxxoPayment,
		GCashPayment,
		PayPalPayment,
		CreditCardPaymentRegisterV2,
	},
	filters: {
		currency,
	},
	mixins: [CommonMix, WindowSizes, PaymentMethods],
	data() {
		return {
			countryData: new Country(),
			stateData: new Country(),
			cart: new Cart(),
			paymentMethodData: new Cart(),
			selectedPaymentMethodName: 0,
			sameAs: false,
			wallets: new Wallets(),
			wallet: {
				type: '',
				password: '',
			},
			walletTypes: WALLET_TYPES,
			type: '',
			form: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				country_code: '',
				region_id: '',
				address_id: '',
			},
			allowAnotherCountry,
			alert: new this.$Alert(),
			appName: process.env.VUE_APP_NAME,
			// addressVerificationCountries,
			showOverrideValidation: false,
			addressBook: new AddressBook(),
			createAddress: new AddressBook(),
			loadAddress: new AddressBook(),
			addAddress: false,
			hasAddressId: false,
			affiliate,
			splitPayment: false,
			splitPaymentAmount: null,
			allowedPaymentMethodsWithWallet: ALLOWED_PAYMENT_METHODS_WITH_WALLET,
			minAmountByPaymentMethodWithWallet: MIN_AMOUNT_BY_PAYMENT_METHODS_WITH_WALLET,
			splitWithWallet: false,
			walletSplitAmount: '',
			walletSplitAgreement: false,
			validation: {},
			showSameAddressAsCardStatementModal: false,
		};
	},
	computed: {
		selectedCardInfo() {
			// eslint-disable-next-line camelcase
			return this.validation?.card_data ?? {};
		},
		isCardPaymentMethod() {
			return CREDIT_CARD_FORM_METHODS.includes(this.selectedPaymentMethodName);
		},
		canSplitWithWallet() {
			return this.$user.auth() && [distributor, affiliate].includes(this.$user.details().type);
		},
		showSectionsAsCards() {
			return this.splitWithWallet && this.allowedPaymentMethodsWithWallet.includes(this.selectedPaymentMethodName);
		},
		hasAmountError() {
			try {
				return !!this.validationErrors[`${this.steps[this.$route.name].validation}.payment_method.wallet_split_amount`].length;
			} catch (e) {
				return false;
			}
		},
		countries() {
			try {
				const response = this.countryData.data.response.data.data;
				if (allowAnotherCountry === false) {
					return response.filter((item) => item.attributes.code === this.country);
				}
				return response;
			} catch (error) {
				return [];
			}
		},
		states() {
			try {
				return this.stateData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		paymentMethods() {
			try {
				return this.paymentMethodData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		currency() {
			try {
				return this.cart.data.response.data.meta.currency;
			} catch (error) {
				return {
					code: 'usd',
					symbol: '$',
				};
			}
		},
		balance() {
			try {
				return this.wallets.data.response.data.response.balance;
			} catch (error) {
				return {};
			}
		},
		addressData() {
			try {
				const { data } = this.addressBook.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		loadingWalletBalance() {
			return !!this.wallets.data.loading;
		},
		addressLoading() {
			return !!this.addressBook.data.loading;
		},
		hasAddressData() {
			const response = this.addressData.length;
			return !!response;
		},
		addressErrors() {
			return this.createAddress.data.errors;
		},
		showSplitPayment() {
			try {
				return this.cartTotals.can_split_payment;
			} catch (error) {
				return false;
			}
		},
		cartTotals() {
			try {
				return this.cart.data.response.data.data.attributes;
			} catch (error) {
				return {};
			}
		},
		cartTotal() {
			try {
				return this.cartTotals.numeric_total || 0;
			} catch (error) {
				return 0;
			}
		},
		canSplitWithWalletConsideringMinAmount() {
			const minAmount = this.minAmountByPaymentMethodWithWallet[this.selectedPaymentMethodName] ?? 0;
			return this.cartTotal > minAmount;
		},
		remainingForOtherMethod() {
			return Number(this.cartTotal - this.walletSplitAmount);
		},
		conditions() {
			const conditions = { ...(this.paymentMethodConditions[this.selectedPaymentMethodName] || { isAvailable: true }) };
			if (Object.keys(conditions).length > 0 && typeof conditions.max !== 'undefined' && this.showSectionsAsCards) {
				conditions.isAvailable = this.remainingForOtherMethod <= conditions.max;
			}
			return conditions;
		},
	},
	watch: {
		sameAs: function sameAs(value) {
			if (value) {
				this.clearValidationError(true);
				const step = Object.keys(this.steps).find((item) => this.steps[item].validation === 'shipping');
				const { shipping } = this.getStepInformation(step);
				Object.keys(shipping.shipping_address).forEach((property) => {
					this.form[property] = shipping.shipping_address[property];
				});
			} else {
				Object.keys(this.form).forEach((key) => {
					this.form[key] = '';
				});
				this.initializeAddress();
				this.form.country_code = this.country;
			}
		},
		hasAddressData: {
			deep: true,
			handler(newVal) {
				this.addAddress = !newVal;
			},
		},
		'form.address_id': function addressId(value) {
			this.clearValidationError('payment.billing.address_id');
			this.hasAddressId = false;
			if (value.length || !!value) {
				this.hasAddressId = true;
				this.setAddressInfo(value);
			}
		},
		canSplitWithWalletConsideringMinAmount(canSplitWithWallet) {
			if (!canSplitWithWallet) {
				this.splitWithWallet = false;
			}
		},
		cartTotal(total) {
			this.setAmountToPay(total);
		},
		selectedPaymentMethodName() {
			if (!this.isCardPaymentMethod) {
				this.preparing = false;
			}
		},
		paymentMethods: {
			deep: true,
			handler() {
				const transformedPaymentMethods = {};
				this.paymentMethods.forEach((method) => {
					transformedPaymentMethods[method.attributes.method_code_name] = method.attributes;
				});
				this.setMethodLimits(transformedPaymentMethods);
			},
		},
	},
	mounted() {
		this.setFlowName('becomeDistributor');
		this.countryData.getCountries();
		this.getGeneralInformation();
		this.form.country_code = this.country;
		this.stateData.getStates(this.form.country_code);
		this.setStoredSameAs();
		this.initializeAddress();
		this.getStoredInformation();
		this.paymentMethodData.getPaymentMethods({ country_code: this.getStoredCountry(), flow: 'become_distributor' }).then(() => {
			if (this.selectedPaymentMethodName === 0) {
				this.selectedPaymentMethodName = this.paymentMethods[0].attributes.method_code_name;
			}
			if (this.$user.details().type === this.affiliate) {
				this.wallet.type = this.walletTypes.commission;
				this.wallets.getBalance();
			}
		}).finally(() => {
			let timeout = 1000;
			if (this.isCardPaymentMethod) {
				timeout = 3000;
			}

			// Waiting for LP Form
			this.preparing = true;
			setTimeout(() => {
				this.preparing = false;
			}, timeout);
		});
		if (this.shoppingCartId !== 'undefined' && this.shoppingCartId !== '') {
			this.cart.getCart(this.shoppingCartId, { is_register: 1 }, 'become_distributor');
		}
		if (!this.canSplitWithWallet) {
			this.splitWithWallet = false;
		}
	},
	methods: {
		handleContinue() {
			if (this.isCardPaymentMethod) {
				this.showSameAddressAsCardStatementModal = true;
			} else {
				this.savePaymentStep();
			}
		},
		savePaymentStep() {
			this.saveStep(this.validation);
		},
		splitPaymentAgreementState() {
			if (['purchaseorder', 'purchaseorderintl', 'purchaseorderintl'].includes(this.selectedPaymentMethodName)) {
				return this.$refs.creditCardV2Register?.splitPaymentAgreementState;
			}
			return true;
		},
		showSplitPaymentMessages() {
			return this.$refs.creditCardV2Register?.showSplitPaymentMessages ?? false;
		},
		verifyAndFixWalletAmount() {
			const minAmount = this.minAmountByPaymentMethodWithWallet[this.selectedPaymentMethodName] ?? 0;
			const maxWalletAmount = Number(Math.max(this.cartTotal - minAmount, 0).toFixed(2));

			if (this.walletSplitAmount > maxWalletAmount) {
				this.walletSplitAmount = maxWalletAmount;
			}
		},
		setStoredSameAs() {
			if (this.getStepInformation().payment !== undefined) {
				const { same_as_shipping: sameAs } = { ...this.getStepInformation().payment.billing };
				if (sameAs === true) {
					const step = Object.keys(this.steps).find((item) => this.steps[item].validation === 'shipping');
					const { shipping } = this.getStepInformation(step);
					Object.keys(shipping.shipping_address).forEach((key) => {
						this.form[key] = shipping.shipping_address[key];
					});
				}
				this.sameAs = sameAs;
			}
		},
		initializeAddress() {
			this.addressBook.getAddresses(this.$user.details().id).then((response) => {
				if (this.form.address_id.length === 0 && response.length) this.form.address_id = response[0].id;
			});
		},
		setAddressInfo(addressId) {
			this.loadAddress.getAddress(this.$user.details().id, addressId).then((response) => {
				this.form.first_name = response.attributes.first_name;
				this.form.last_name = response.attributes.last_name;
				this.form.address = response.attributes.address;
				this.form.address2 = response.attributes.address2;
				this.form.city = response.attributes.city;
				this.form.postcode = response.attributes.postcode;
				this.form.country_code = response.attributes.country.code;
				this.form.region_id = response.attributes.region.id;
			}).catch(() => {
				this.form.address_id = '';
				this.initializeAddress();
			});
		},
		getGeneralInformation() {
			const step = Object.keys(this.steps).find((item) => this.steps[item].validation === 'shipping');
			const previous = this.getStepInformation(step);
			try {
				const { shipping_address: shippingAddress } = previous.shipping;
				if (typeof shippingAddress === 'undefined') {
					this.nextStep(true);
					return null;
				}
			} catch (error) {
				this.nextStep(true);
				return null;
			}
			this.sameAs = true;
			return null;
		},
		getStoredInformation() {
			const { payment } = { ...this.getStepInformation() };
			if (typeof payment !== 'undefined' && typeof payment.payment_method !== 'undefined') {
				this.selectedPaymentMethodName = payment.payment_method.name;
				if (this.selectedPaymentMethodName === 'cashondelivery' && this.$user.details().type === this.affiliate) {
					this.wallet.type = payment.payment_method.wallet_type;
				}
				if (['purchaseorder', 'purchaseorderintl'].includes(this.selectedPaymentMethodName) && !!payment.payment_method.split_amount) {
					this.splitPayment = true;
					this.splitPaymentAmount = payment.payment_method.split_amount;
				}
				// eslint-disable-next-line camelcase
				this.walletSplitAmount = payment.payment_method?.wallet_split_amount || 0;
				// eslint-disable-next-line camelcase
				this.splitWithWallet = payment.payment_method?.wallet_split || false;
			}
			try {
				const { billing: data } = payment;
				const { country_code: countryCode } = data;
				Object.keys(this.form).forEach((key) => {
					if (typeof data[key] !== 'undefined') {
						this.form[key] = data[key];
					}
				});
				if (countryCode !== this.country) {
					this.form.region_id = '';
				}
			} catch (error) {
				return null;
			}
			return null;
		},
		confirmSplitPayment() {
			const trans = {
				title: this.translate('important_note').toUpperCase(),
				text: this.translate('split_payment_disclaimer'),
			};
			const options = {
				config: {
					icon: 'warning',
					confirmButtonText: this.translate('understand'),
					cancelButtonText: this.translate('cancel'),
					allowOutsideClick: false,
					allowEscapeKey: false,
					confirmButtonColor: '#eb772f',
					showCancelButton: true,
					reverseButtons: true,
				},
			};
			const alert = new this.$Alert();
			alert.confirmation(trans.title, trans.text, options).then(() => {
				this.prepareForStorage();
			}).catch(() => {});
		},
		async prepareForStorage() {
			this.preparing = true;
			let billing = { ...this.form, same_as_shipping: this.sameAs };
			if (this.hasAddressId) {
				billing = { address_id: this.form.address_id, same_as_shipping: this.sameAs };
			}
			this.validation = { step: this.steps[this.$route.name].validation };

			let paymentMethod = { name: this.selectedPaymentMethodName };

			if (this.isCardPaymentMethod) {
				await this.$refs.creditCardV2Register.lacoreV2HandleResponse();

				// Use single payment
				if (!this.$refs.creditCardV2Register?.splitPayment) {
					paymentMethod.token = this.$refs.creditCardV2Register.lacoreV2PaymentToken;
				}

				// Set multiple credit card tokens and amounts if selecting split payments
				if (this.$refs.creditCardV2Register?.splitPayment) {
					paymentMethod.split_amount = this.$refs.creditCardV2Register.splitPaymentAmount;
					this.$refs.creditCardV2Register.lacoreV2PaymentTokens.forEach((token, index) => {
						// Generate and fill token parameter keys as such:
						// 	payment.token
						// 	payment.token_2
						// 	payment.token_3
						// 	...
						const tokenKeySuffix = index > 0 ? `_${index + 1}` : '';
						const tokenKey = `token${tokenKeySuffix}`;
						paymentMethod[tokenKey] = token;
					});
				}

				// eslint-disable-next-line no-underscore-dangle
				paymentMethod.sift_session_id = window._sift_session_id;
			}

			if (this.selectedPaymentMethodName === 'cashondelivery' && this.$user.details().type === this.affiliate) {
				const wallet = { ...this.wallet };
				paymentMethod.wallet_type = wallet.type;
				paymentMethod.wallet_password = wallet.password;
			}

			if (['oxxopay', 'sezzle', 'lptpaypal'].includes(this.selectedPaymentMethodName)) {
				// eslint-disable-next-line no-underscore-dangle
				paymentMethod.sift_session_id = window._sift_session_id;
			}

			const walletSplitSettings = this.showSectionsAsCards ? {
				wallet_split: this.splitWithWallet,
				wallet_split_amount: this.walletSplitAmount || 0,
				wallet_password: this.wallet.password,
				wallet_type: WALLET_TYPES.commission,
			} : { wallet_split: false };

			paymentMethod = { ...paymentMethod, ...walletSplitSettings };
			const payment = { billing, payment_method: paymentMethod };
			this.validation = { ...this.validation, payment };
			this.validateStep(this.validation).then((response) => {
				this.validation = { ...this.validation, card_data: { ...response.response } };
				if (!(this.$refs.creditCardRegister?.lacoreV1Failed ?? false) && !(this.$refs.creditCardV2Register?.lacoreV2Failed ?? false)) {
					this.handleContinue();
				}
			}).catch(() => {
				// Split CC payment amount errors
				if (
					typeof this.validationErrors[`${this.steps[this.$route.name].validation}.payment_method.split_amount`] !== 'undefined'
					&& CREDIT_CARD_FORM_METHODS.includes(this.selectedPaymentMethodName)
				) {
					this.$refs.creditCardV2Register.amountErrors = this.validationErrors[`${this.steps[this.$route.name].validation}.payment_method.split_amount`];
				}

				// Single CC payment and Split CC payment #1 errors
				if (
					typeof this.validationErrors[`${this.steps[this.$route.name].validation}.payment_method.token`] !== 'undefined'
					&& CREDIT_CARD_FORM_METHODS.includes(this.selectedPaymentMethodName)
					&& !this.$refs.creditCardV2Register.lacoreV2Failed
				) {
					this.$refs.creditCardV2Register.lacoreV2CreditCardErrors.number.errorMessages = this.validationErrors[`${this.steps[this.$route.name].validation}.payment_method.token`];
				}

				// Split CC payment #2 errors
				if (
					typeof this.validationErrors[`${this.steps[this.$route.name].validation}.payment_method.token_2`] !== 'undefined'
					&& CREDIT_CARD_FORM_METHODS.includes(this.selectedPaymentMethodName)
					&& !this.$refs.creditCardV2Register.lacoreV2Failed
				) {
					this.$refs.creditCardV2Register.lacoreV2CreditCard2Errors.number.errorMessages = this.validationErrors[`${this.steps[this.$route.name].validation}.payment_method.token_2`];
				}

				// CC v2 payment errors
				if (
					typeof this.validationErrors[`${this.steps[this.$route.name].validation}.payment_method.token`] !== 'undefined'
					&& CREDIT_CARD_FORM_METHODS.includes(this.selectedPaymentMethodName)
					&& !this.$refs.creditCardV2Register.lacoreV2Failed
				) {
					this.$refs.creditCardV2Register.lacoreV2CreditCardErrors.number.errorMessages = this.validationErrors[`${this.steps[this.$route.name].validation}.payment_method.token`];
				}
			}).finally(() => { this.preparing = false; });
			return null;
		},
		handleAddressFormChange(target) {
			if (target === 'billing.billing.country_code') {
				this.stateData.getStates(this.form.country_code).then(() => {
					this.form.region_id = '';
				});
			}
			this.clearValidationError(target);
		},
		addressFormState() {
			if (!this.addAddress) {
				this.clearAddressForm();
			} else {
				this.setAddressInfo(this.form.address_id);
			}
			if (Object.keys(this.addressErrors.errors).length > 0) {
				const errorFields = Object.keys(this.addressErrors.errors);
				errorFields.forEach((field) => {
					delete this.addressErrors.errors[field];
					this.addressErrors.errors = { ...this.addressErrors.errors };
				});
			}
			this.addAddress = !this.addAddress;
		},
		clearAddressForm() {
			this.form.first_name = '';
			this.form.last_name = '';
			this.form.address = '';
			this.form.address2 = '';
			this.form.city = '';
			this.form.postcode = '';
			// this.form.country_code = '';
			this.form.region_id = '';
			this.showOverrideValidation = false;
		},
		newAddress(form) {
			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'), options);

			const payload = { ...form };
			payload.address_type = 'billing';
			// if (!this.showOverrideValidation) {
			// 	payload.override_validation = undefined;
			// }

			this.createAddress.saveAddress(this.$user.details().id, payload).then(() => {
				this.alert.toast('success', this.translate('address_created'), { timer: 6000 });

				this.addressBook.getAddresses(this.$user.details().id).then((response) => {
					if (response.length) {
						const lastAddressPos = response.length - 1;
						this.form.address_id = response[lastAddressPos].id;
					}
				});

				this.addAddress = !this.addAddress;
			}).catch(() => {
				// if (UNPROCESSABLE.includes(this.addressErrors.status)) {
				// 	Object.keys(this.addressErrors.errors).forEach((errorKey) => {
				// 		if (errorKey === 'suggestion') {
				// 			this.getSuggestion.getSuggestion(form).then((suggestionResponse) => {
				// 				let address = '';
				// 				Object.keys(suggestionResponse.response.address).forEach((item, index) => {
				// 					if (suggestionResponse.response.address[item] !== '' && item !== 'region_id') {
				// 						address += `${suggestionResponse.response.address[item]}`;
				// 						if (index < (Object.keys(suggestionResponse.response.address).length) - 2) {
				// 							address += ', ';
				// 						}
				// 					}
				// 				});
				// 				const trans = {
				// 					title: this.translate('invalid_address'),
				// 					text: this.translate('suggestion_address', { address }),
				// 				};
				// 				const options = {
				// 					config: {
				// 						icon: 'error',
				// 						showCancelButton: true,
				// 						confirmButtonText: this.translate('accept_sugestion'),
				// 						cancelButtonText: this.translate('manual_change'),
				// 						reverseButtons: true,
				// 						allowOutsideClick: false,
				// 						allowEscapeKey: false,
				// 					},
				// 				};
				// 				this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
				// 					Object.keys(suggestionResponse.response.address).forEach((suggestionItem) => {
				// 						Object.keys(this.form).forEach((formItem) => {
				// 							if (formItem === suggestionItem) {
				// 								this.form[formItem] = suggestionResponse.response.address[suggestionItem];
				// 							}
				// 						});
				// 					});
				// 					this.newAddress(this.form);
				// 				}).catch(() => {});
				// 			}).catch(() => {});
				// 		} else if (errorKey === 'address' && !!this.form.address.trim().length && this.addressVerificationCountries.includes(this.form.country_code)) {
				// 			this.showOverrideValidation = true;
				// 		}
				// 	});
				// }
				if (SYSTEM_ALERT.includes(this.addressErrors.status) || this.addressErrors.status === undefined) {
					this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				} else {
					this.alert.close();
				}
			});
		},
	},
};
</script>

<style>
	.address-list .card-body {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
</style>
