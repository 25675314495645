import { BECOME_DISTRIBUTOR_STEPS } from '@/settings/Wizard';
import {
	Profile, Purchase, Products, Countries, Grids, Users, Dates, NIN, Addresses, Validations, Packages, Months, PaymentMethods, Events,
} from '@/translations';
import RegisterClass from '@/util/Purchase';
import Captcha from '@/mixins/Captcha';
import store from '../store';

export default {
	messages: [Validations, Profile, Dates, NIN, Addresses, Grids, Purchase, Products, Countries, Users, Packages, Months, PaymentMethods, Events],
	mixins: [Captcha],
	data() {
		return {
			validator: new RegisterClass(),
			preparing: 0,
		};
	},
	computed: {
		steps() {
			return BECOME_DISTRIBUTOR_STEPS;
		},
		validating() {
			try {
				return this.validator.data.loading;
			} catch (error) {
				return false;
			}
		},
		validationErrors() {
			try {
				return this.validator.errors.errors;
			} catch (error) {
				return [];
			}
		},
		currentModule() {
			const [mod] = this.$route.matched;
			return mod.name;
		},
		shoppingCartId() {
			try {
				return store.getters.getStepInformation('cartId');
			} catch (error) {
				return '';
			}
		},
		storedSubtotal() {
			try {
				return store.getters.getStepInformation('subtotal');
			} catch (error) {
				return '';
			}
		},
		paymentMethodStored() {
			const { payment } = { ...store.getters.getStepInformation('BecomeDistributorPaymentMethod') };
			// This linting disabler must be removed when the linter is upgraded
			// See: https://github.com/babel/eslint-plugin-babel/pull/163
			// eslint-disable-next-line camelcase
			return payment?.payment_method?.name;
		},
	},
	methods: {
		getAttributeByCodename(codename, attr) {
			const product = this.products.find((item) => item.attributes.code_name === codename);
			if (typeof product === 'undefined') {
				return '';
			}
			if (typeof attr === 'undefined') {
				return product.attributes;
			}
			return product.attributes[attr];
		},
		nextStep(previous) {
			const { name } = this.$route;
			let stepIndex = 0;
			let stepsList = this.steps;
			Object.keys(this.steps).forEach((item, index) => {
				if (item === name) {
					stepIndex = previous === null ? index + 1 : index - 1;
					stepsList = this.steps;
				}
			});
			this.$router.push({ name: Object.keys(stepsList)[stepIndex] }).catch();
		},
		validateStep(payload) {
			if (this.validating) {
				return null;
			}
			return this.getCaptchaToken('become_distributor').then((token) => {
				payload['g-recaptcha-response'] = token;
				payload.cart_id = this.shoppingCartId;
				return this.validator.validate(payload, this.currentModule).then((response) => response).catch((error) => Promise.reject(error));
			});
		},
		clearValidationError(field) {
			this.validator.errors.clear(field);
		},
		saveStep(data) {
			const { step } = data;
			this.storeStepInfo(data, step).then(() => {
				this.nextStep(null);
			}).catch();
		},
		storeStepInfo(data, step) {
			let { name } = this.$route;
			if (Object.keys(this.steps).includes(step)) {
				name = step;
			}
			const payload = {};
			payload[name] = data;
			return store.dispatch('saveInfo', payload);
		},
		getStepInformation(step) {
			let currentStep = step;
			if (typeof currentStep === 'undefined') {
				currentStep = this.$route.name;
			}
			return store.getters.getStepInformation(currentStep);
		},
		saveCart(cartId) {
			return store.dispatch('saveCart', cartId);
		},
		saveSubtotal(subtotal) {
			return store.dispatch('saveSubtotal', subtotal);
		},
		removeRegister() {
			return store.dispatch('removeRegister').catch(() => {});
		},
		validatePacksStep(redirect, step) {
			const payload = {
				product: {
					is_pack: 0,
					products: {},
				},
			};
			this.storeStepInfo(payload, step).then(() => {
				if (redirect) {
					this.$router.push({ name: 'BecomeDistributorPack' });
				}
			}).catch(() => {});
		},
	},
};
