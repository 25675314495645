import Vue from 'vue';
import Vuex from 'vuex';
import { BECOME_DISTRIBUTOR_STEPS } from '@/settings/Wizard';

const options = {};
Object.keys(BECOME_DISTRIBUTOR_STEPS).forEach((key) => { options[key] = {}; });

Vue.use(Vuex);

const Store = new Vuex.Store({
	strict: process.env.VUE_APP_ENV !== 'production',
	state: {
		subtotal: 0,
		cartId: '',
		...options,
	},
	getters: {
		getStepInformation(state) {
			return (payload) => {
				if (Object.keys(state).includes(payload)) {
					return state[payload];
				}
				return null;
			};
		},
	},
	mutations: {
		setState(state, payload) {
			const [name] = Object.keys(payload);
			state[name] = payload[name];
		},
	},
	actions: {
		saveSubtotal({ commit }, payload) {
			try {
				commit('setState', { subtotal: payload });
				return Promise.resolve(true);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		saveCart({ commit }, payload) {
			try {
				commit('setState', { cartId: payload });
				return Promise.resolve(true);
			} catch (error) {
				return Promise.reject(error);
			}
		},
		saveInfo({ commit }, payload) {
			const [name] = Object.keys(payload);
			if (Object.keys(options).includes(name)) {
				commit('setState', payload);
				return Promise.resolve(true);
			}
			return Promise.reject(new Error('invalid_step'));
		},
		removeRegister({ commit }) {
			Object.keys(options).forEach((item) => {
				const payload = {};
				payload[item] = {};
				commit('setState', payload);
			});
		},
		removeCart({ commit }) {
			commit('setState', { cartId: '' });
		},
	},
});

export default Store;
